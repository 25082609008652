/* add css module styles here (optional) */

._34Oew {
    position: fixed;
    bottom: 15px;
    left: 15px;
    font-size: 16px;
    transition: bottom 0.2s;
}

._34Oew ._1zufg {
    position: relative;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba(60, 60, 60, 0.4);
    transition: box-shadow 0.2s;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

._34Oew ._1zufg img,
._34Oew ._1zufg svg {
    width: 70%;
    height: 70%;
}

._34Oew:hover {
    bottom: 17px;
}

._34Oew:hover ._1zufg {
    box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.4);
}

._34Oew ._1jTF6 {
    /*border: 2px solid white;*/
    border-radius: 6px;
    background-color: #E5DDD5;
    position: absolute;
    overflow: hidden;
    padding: 0;
    box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.25);
    width: 0px;
    height: 0px;
    bottom: 0;
    opacity: 0;
    transition: bottom 0.1s ease-out, opacity 0.2s ease-out;
    transform-origin: bottom;
}

._34Oew ._1jTF6._3sodH {
    padding: 0 12px 12px 12px;
    width: 260px;
    height: auto;
    bottom: 82px;
    opacity: 1;
}

._34Oew ._1jTF6 ._uThfQ {
    background-color: white;
    padding: 8px;
    border-radius: 0px 5px 5px 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s;
}

._34Oew ._1jTF6._3sodH ._uThfQ {
    opacity: 1;
    transition-delay: 0.2s;
}

._34Oew ._1jTF6 ._LDfCy {
    text-align: right;
    color: white;
    margin: 0 -15px 10px -15px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

._34Oew ._22mst {
    background-color: white;
    margin: 10px -15px -15px -15px;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

._34Oew ._22mst textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: none;
    padding: 8px;
    margin: 10px 0;
    width: 100%;
    max-width: 100%;
    font-family: inherit;
    font-size: inherit;
    resize: none;
}

._34Oew ._NeBrT {
    margin-left: 12px;
    font-size: 0;
    cursor: pointer;
    height: 18px;
    width: 20px;
}
